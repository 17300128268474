import React from "react";
import "./App.css";
import { Layout } from "./layout/layout";
import { Events } from "./events/Events";
import { Attendees } from "./events/Attendees";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { indigo, teal } from "@material-ui/core/colors";
import { LocalizationProvider } from "@material-ui/pickers";
import LuxonUtils from "@material-ui/pickers/adapter/luxon";
import { Settings as LuxonSettings } from "luxon";
import { MediaView } from "./media/MediaView";
import { BoardMembers } from "./boardMembers/BoardMembers";
import { Settings } from "./settings";
import { SnackbarProvider } from "notistack";

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: {
      main: teal[700],
    },
  },
});

LuxonSettings.defaultLocale = "de";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={LuxonUtils} locale="de">
          <SnackbarProvider>
            <Layout
              events={<Events />}
              attendees={<Attendees />}
              media={<MediaView />}
              boardMembers={<BoardMembers />}
              appSettings={<Settings />}
            />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
