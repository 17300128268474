import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  Container,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { EventDialog } from "./EventDialog";
import { useCallback } from "react";
import { DateTime } from "luxon";
import { useData } from "../logic/backendFetch";
import { DataLayout } from "../components/DataLayout";
import { deleteData, updateOrCreateData } from "../logic/api";

function Events() {
  const [currentEvent, setCurrentEvent] = useState(null);

  const { data, loading, error, reload } = useData("event");

  const addNewEvent = useCallback(() => {
    setCurrentEvent({
      start: DateTime.local().startOf("day").toISO(),
      end: DateTime.local().startOf("day").toISO(),
    });
  }, []);

  const handleSave = useCallback(
    (event) => {
      const { contact, ...rest } = event;
      updateOrCreateData("event", rest)
        .then(reload)
        .catch((error) => alert("Fehler!"));
    },
    [reload]
  );

  const handleDelete = useCallback(
    (event) => {
      deleteData("event", event.id)
        .then(reload)
        .catch((error) => alert("Fehler!"));
    },
    [reload]
  );

  return (
    <DataLayout
      loading={loading}
      error={error}
      fab={{ onClick: addNewEvent, icon: AddIcon, title: "Event erstellen" }}
    >
      {data && (
        <div style={{ position: "relative", height: "100%" }}>
          {data.length === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              <img
                src="/undraw_festivities_tvvj.svg"
                alt=""
                style={{ height: "40vh" }}
              />
              <br />
              <Typography variant="h5" color="primary">
                Erstelle ein neues Event
              </Typography>
            </div>
          )}
          {data.length > 0 && (
            <Container style={{ paddingTop: 25 }}>
              <Paper variant="outlined" square>
                <List>
                  {data.map((event) => {
                    const isOld =
                      DateTime.fromISO(event.end) <
                      DateTime.local().startOf("day");
                    return (
                      <ListItem
                        key={event.id}
                        button
                        onClick={() => {
                          setCurrentEvent(event);
                        }}
                      >
                        <ListItemText
                          primary={event.name}
                          secondary={
                            isOld ? (
                              <Typography variant="caption" color="error">
                                Am{" "}
                                {DateTime.fromISO(event.end).toLocaleString()}{" "}
                                beendet
                              </Typography>
                            ) : null
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            color={isOld ? "secondary" : "default"}
                            onClick={(ev) => {
                              handleDelete(event);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Container>
          )}
          <EventDialog
            currentEvent={currentEvent}
            onClose={() => {
              setCurrentEvent(null);
            }}
            onSave={handleSave}
          />
        </div>
      )}
    </DataLayout>
  );
}

export { Events };
