import React from "react";
import { TextField, Button } from "@material-ui/core";
import { useState } from "react";

function AuthSetting({ reload }) {
  const [text, setText] = useState(localStorage.auth);
  return (
    <div>
      <TextField
        variant="outlined"
        label="Passwort"
        type="password"
        value={text}
        onChange={(ev) => setText(ev.target.value)}
        InputProps={{
          endAdornment: (
            <Button
              disabled={!text || text.length === 0}
              onClick={() => {
                localStorage.auth = text;
                if (reload) {
                  window.location.reload();
                }
              }}
            >
              OK
            </Button>
          ),
        }}
      />
    </div>
  );
}

export { AuthSetting };
