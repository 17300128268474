import { useTheme } from "@material-ui/core";
import React from "react";
import { AuthSetting } from "../components/AuthSetting";
import { Navigation } from "./Navigation";

function Layout(props) {
  const theme = useTheme()
  const views = [
    props.events,
    props.attendees,
    props.media,
    props.boardMembers,
    props.history,
    props.appSettings,
  ];
  const [value, setValue] = React.useState(1);
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ flexGrow: 1, overflowY: "auto", padding: theme.spacing() }}>
        {!localStorage.auth && <AuthSetting reload />}
        {views[value]}
      </div>
      <Navigation value={value} onChange={setValue} />
    </div>
  );
}

export { Layout };
