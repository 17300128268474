import { makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { AuthSetting } from "./components/AuthSetting";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export function Settings() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" color="primary">
        Einstellungen
      </Typography>
      <br />
      <Paper variant="elevation" elevation={4} className={classes.paper}>
        <Typography variant="h6">Admin App Passwort</Typography>
        <br />
        <AuthSetting />
      </Paper>
    </div>
  );
}
