import React from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { formatPhone, generateAvatarLink } from "../logic";

export function ContactCard({ contact, contactId, onRemove, ...rest }) {
  return (
    <Card variant="outlined" {...rest}>
      <Typography variant="caption" color="textSecondary">
        &nbsp;Kontaktperson
      </Typography>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item>
            <Avatar
              src={generateAvatarLink(contactId || contact.id)}
              style={{ height: "8vmax", width: "8vmax" }}
            />
          </Grid>
          <Grid item>
            <Typography>{contact.name}</Typography>
            <Typography variant="subtitle2">
              {formatPhone(contact.phone)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          startIcon={<DeleteIcon />}
          size="small"
          variant="outlined"
          onClick={onRemove}
        >
          Kontaktperson entfernen
        </Button>
      </CardActions>
    </Card>
  );
}
