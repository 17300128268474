import { API_BASE } from "./config";

function formatPhone(phone) {
  return `${phone.substr(0, 3)} ${phone.substr(3, 4)} ${phone.substr(7)}`;
}

function generateAvatarLink(id) {
  return `${API_BASE}/boardmember/avatar/${id}`;
}

export { formatPhone, generateAvatarLink };
