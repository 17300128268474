import React, { useMemo, useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
  Menu,
  MenuItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DateTimePicker } from "@material-ui/pickers";
import { useEffect } from "react";
import { useCallback } from "react";
import { DateTime } from "luxon";
import { generateAvatarLink } from "../logic";
import { useData } from "../logic/backendFetch";
import { ContactCard } from "./ContactCard";
import { AttendeesSettings } from "./AttendeesSettings";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  id: {
    marginBottom: theme.spacing(2),
  },
}));

function EventDialog({ currentEvent, onClose, onSave }) {
  const classes = useStyles();

  const [contactMenuAnchorEl, setContactMenuAnchorEl] = useState(null);

  const [event, setEvent] = useState(null);

  useEffect(() => {
    setEvent(currentEvent);
  }, [currentEvent]);

  const boardMember = useData("boardmember");

  const contacts = useMemo(() => {
    if (!boardMember.loading && !boardMember.error) return boardMember.data;
  }, [boardMember.data, boardMember.error, boardMember.loading]);

  const handleSave = useCallback(() => {
    onSave(event);
    onClose();
  }, [event, onClose, onSave]);

  if (event) {
    return (
      <Dialog fullScreen open onClose={onClose}>
        <AppBar color="inherit">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div style={{ width: "100%" }}></div>
            <Button color="secondary" onClick={handleSave} variant="contained">
              Speichern
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <Typography
            variant="caption"
            color="secondary"
            className={classes.id}
          >
            ID: {event.id}
          </Typography>

          <TextField
            label="Name"
            variant="outlined"
            className={classes.field}
            value={event.name}
            onChange={(ev) => setEvent({ ...event, name: ev.target.value })}
          />

          <DateTimePicker
            value={DateTime.fromISO(event.start)}
            onChange={(date) => setEvent({ ...event, start: date.toISO() })}
            renderInput={(props) => (
              <TextField
                {...props}
                variant="outlined"
                className={classes.field}
              />
            )}
            ampm={false}
            cancelLabel="Abbrechen"
            toolbarTitle="Startzeitpunkt wählen"
            disablePast
            hideTabs
            invalidDateMessage="Ungültiges Datumsformat"
          />

          <DateTimePicker
            value={DateTime.fromISO(event.end)}
            onChange={(date) => setEvent({ ...event, end: date.toISO() })}
            renderInput={(props) => (
              <TextField
                {...props}
                variant="outlined"
                className={classes.field}
              />
            )}
            ampm={false}
            cancelLabel="Abbrechen"
            toolbarTitle="Endzeitpunkt wählen"
            disablePast
            hideTabs
            invalidDateMessage="Ungültiges Datumsformat"
          />

          <TextField
            label="Text"
            variant="outlined"
            className={classes.field}
            multiline
            rows={5}
            value={event.text}
            onChange={(ev) => setEvent({ ...event, text: ev.target.value })}
          />

          <AttendeesSettings event={event} onChange={setEvent} />

          <TextField
            label="Kosten (Bar)"
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            className={classes.field}
            variant="outlined"
            type="number"
            value={event.cost}
            onChange={(ev) => {
              const { cost, ...rest } = event;
              if (ev.target.value !== "") {
                setEvent({ ...rest, cost: parseFloat(ev.target.value, 10) });
              } else {
                setEvent(rest);
              }
            }}
          />
          <TextField
            label="Anmeldung über IBAN"
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            className={classes.field}
            variant="outlined"
            type="number"
            value={event.registrationByPayment}
            onChange={(ev) => {
              const { registrationByPayment, ...rest } = event;
              if (ev.target.value !== "") {
                setEvent({
                  ...rest,
                  registrationByPayment: parseFloat(ev.target.value, 10),
                });
              } else {
                setEvent(rest);
              }
            }}
          />

          <TextField
            label="Link"
            className={classes.field}
            variant="outlined"
            type="url"
            value={event.link}
            onChange={(ev) => {
              const { link, ...rest } = event;
              if (ev.target.value !== "") {
                setEvent({ ...rest, link: ev.target.value });
              } else {
                setEvent(rest);
              }
            }}
          />

          {event.contact && (
            <ContactCard
              className={classes.field}
              contactId={event.contactId}
              contact={event.contact}
              onRemove={() => {
                const { contact, ...rest } = event;
                rest.contactId = null
                setEvent(rest);
              }}
            />
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={(event) => {
              setContactMenuAnchorEl(event.currentTarget);
            }}
            disabled={!contacts}
          >
            Kontaktperson {(event.contact && "ändern") || "hinzufügen"}
          </Button>
          <Menu
            anchorEl={contactMenuAnchorEl}
            keepMounted
            open={Boolean(contactMenuAnchorEl)}
            onClose={() => setContactMenuAnchorEl(null)}
          >
            {contacts?.map(({ id, name, phone }) => (
              <MenuItem
                key={name}
                onClick={() => {
                  setContactMenuAnchorEl(null);
                  setEvent({
                    ...event,
                    contactId: id,
                    contact: {
                      name,
                      phone
                    },
                  });
                }}
                disabled={!phone}
              >
                <ListItemAvatar>
                  <Avatar src={generateAvatarLink(id)} />
                </ListItemAvatar>
                <ListItemText
                  primary={name}
                  secondary={!phone && "Keine Telefonnummer hinterlegt"}
                />
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Dialog>
    );
  } else {
    return null;
  }
}

export { EventDialog };
