import React, { useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  ButtonGroup,
  Avatar,
  ListItemAvatar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EmptyIcon from "@material-ui/icons/NotInterested";
import RemoveIcon from "@material-ui/icons/Remove";
import { indigo, green, red, orange } from "@material-ui/core/colors";
import { useData } from "../logic/backendFetch";
import { DataLayout } from "../components/DataLayout";
import { updateOrCreateData } from "../logic/api";

const OP = {
  ADD: 0,
  REM: 1,
};

const useStyles = makeStyles((theme) => ({
  default: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
  },
  orange: {
    color: "#fff",
    backgroundColor: orange[500],
  },
  red: {
    color: "#fff",
    backgroundColor: red[700],
  },
  listText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function Attendees() {
  const classes = useStyles();
  const { data, loading, error, reload } = useData("event");

  const updateAttendees = useCallback(
    (id, operation) => {
      let event = data.find((e) => e.id === id);
      event.attendeesCurrent += operation === OP.ADD ? 1 : -1;

      updateOrCreateData("event", event)
        .then(reload)
        .catch((error) => alert("Fehler!"));
    },
    [data, reload]
  );

  return (
    <DataLayout loading={loading} error={error}>
      {data && data.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          <img
            src="/undraw_Waiting__for_you_ldha.svg"
            alt=""
            style={{ height: "40vh" }}
          />
          <br />
          <Typography variant="h5" color="primary">
            Derzeit kein Event
          </Typography>
        </div>
      )}
      {data && data.length > 0 && (
        <List>
          {data.map(({ id, name, attendeesCurrent, attendeesMax }) => {
            const hasAttendees = Number.isInteger(attendeesCurrent);
            return (
              <ListItem key={name}>
                <ListItemAvatar>
                  {!hasAttendees && (
                    <Avatar>
                      <EmptyIcon/>
                    </Avatar>
                  )}
                  {hasAttendees && (
                    <Avatar
                      className={
                        attendeesMax
                          ? attendeesCurrent / attendeesMax < 0.7
                            ? classes.green
                            : attendeesCurrent / attendeesMax < 0.95
                              ? classes.orange
                              : classes.red
                          : classes.default
                      }
                    >
                      {attendeesCurrent}
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={name}
                  secondary={
                    hasAttendees
                      ? attendeesMax
                        ? `Maximal ${attendeesMax} Teilnehmer`
                        : null
                      : "Teilnehmer Funktion nicht aktiviert"
                  }
                  primaryTypographyProps={{ className: classes.listText }}
                  secondaryTypographyProps={{ className: classes.listText }}
                />
                <ButtonGroup color="primary" disabled={!hasAttendees}>
                  <Button
                    disabled={attendeesCurrent < 1}
                    onClick={() => {
                      updateAttendees(id, OP.REM);
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button
                    disabled={
                      hasAttendees && attendeesCurrent >= (attendeesMax || 5000)
                    }
                    onClick={() => {
                      updateAttendees(id, OP.ADD);
                    }}
                  >
                    <AddIcon />
                  </Button>
                </ButtonGroup>
              </ListItem>
            );
          })}
        </List>
      )}
    </DataLayout>
  );
}

export { Attendees };
