import {
  FormControlLabel,
  makeStyles,
  Paper,
  Slider,
  Switch,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  sub: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const MARKS = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 150,
    label: "150",
  },
];

export function AttendeesSettings({ event, onChange }) {
  const classes = useStyles();
  const hasAttendees = useMemo(() => Number.isInteger(event.attendeesCurrent), [
    event,
  ]);

  const sliderValue = useMemo(() => {
    if (Number.isInteger(event.attendeesMax)) {
      return [event.attendeesCurrent, event.attendeesMax];
    } else {
      return event.attendeesCurrent;
    }
  }, [event.attendeesCurrent, event.attendeesMax]);

  const addLimit = useCallback(
    (ev) => {
      const eventWithAttendees = {
        ...event,
        attendeesCurrent: 0,
        attendeesMax: ev.target.checked ? 50 : null,
      };
      onChange(eventWithAttendees);
    },
    [event, onChange]
  );

  const changeAttendees = useCallback(
    (ev, newValue) => {
      const newEvent = {
        ...event,
        attendeesCurrent: Array.isArray(newValue) ? newValue[0] : newValue,
      };

      if (Array.isArray(newValue)) {
        newEvent.attendeesMax = newValue[1];
      }

      onChange(newEvent);
    },
    [event, onChange]
  );

  const toggleAttendees = useCallback(
    (ev) => {
      const nextEvent = {
        ...event,
      };
      if (ev.target.checked) {
        nextEvent.attendeesCurrent = 0;
        nextEvent.attendeesMax = 50;
      } else {
        nextEvent.attendeesCurrent = null;
        nextEvent.attendeesMax = null;
      }
      onChange(nextEvent);
    },
    [event, onChange]
  );

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={hasAttendees}
            onChange={toggleAttendees}
            name="TeilnehmerAnzeige"
            color="primary"
          />
        }
        label="Teilnehmer Anzeige"
      />
      {hasAttendees && (
        <Paper className={classes.sub}>
          <Slider
            value={sliderValue}
            min={0}
            max={150}
            step={1}
            onChange={changeAttendees}
            valueLabelDisplay="auto"
            marks={MARKS}
          />
          <FormControlLabel
            control={
              <Switch
                checked={event.attendeesMax}
                onChange={addLimit}
                name="TeilnehmerGrenze"
                color="primary"
              />
            }
            label="Teilnehmer begrenzen"
          />
        </Paper>
      )}
    </>
  );
}
