import axios from "axios";
import { useEffect, useState } from "react";
import { camelCase as _camelCase } from "lodash";
import { API_BASE } from "../config";

export function modifyJson(data, func) {
  if (Array.isArray(data)) {
    return data.map((item) => modifyJson(item, func));
  } else {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        return [
          func(key),
          (value !== null &&
            typeof value === "object" &&
            modifyJson(value, func)) ||
            value,
        ];
      })
    );
  }
}

export const backendFetch = async (route) => {
  return await axios.get(`${API_BASE}/${route}`);
};

export const backendFetchJson = async (route) => {
  const res = await backendFetch(route);
  return modifyJson(res.data, _camelCase);
};

export const useData = (route, query = {}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const load = () => {
    setLoading(true);
    setError(false);
    const params = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => params.append(key, value));
    backendFetchJson(`${route}?${params}`)
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((er) => {
        console.error(er);
        setError(true);
      });
  };

  // do not add query to dep array!
  useEffect(load, [route]);

  const reload = load;
  return { loading, data, error, reload };
};
