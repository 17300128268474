import { DropzoneDialog } from "material-ui-dropzone";
import React from "react";

function ImageUpload(props) {
  return (
    <DropzoneDialog
      filesLimit={1}
      {...props}
      cancelButtonText="Abbrechen"
      submitButtonText="Hochladen"
      dropzoneText="Bild hier raufziehen oder klicken"
      previewText="Vorschau:"
      acceptedFiles={["image/jpeg", "image/png", "image/gif"]}
      maxFileSize={3500000}
      showPreviewsInDropzone={true}
      showPreviews={false}
      showFileNames
      getFileLimitExceedMessage={(limit) =>
        `Maximal anzahl an Bildern erreicht. Es sind ${limit} Dateien erlaubt`
      }
      getFileAddedMessage={(fileName) => `${fileName} hinzugefügt`}
      getFileRemovedMessage={(fileName) => `${fileName} entfernt`}
      getDropRejectMessage={(file) => `${file.name} ist nicht erlaubt.`}
    />
  );
}

export { ImageUpload };
