import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AuthSetting } from "../components/AuthSetting";

export function AuthErrorPage() {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          LJ WOMA Admin Verwaltungsseite
        </Typography>
        <Typography color="error" variant="h4">
          Nicht authentifiziert
        </Typography>
        <Typography variant="subtitle1">Melde dich erneut an</Typography>
        <br />
        <br />
        <AuthSetting reload />
      </Grid>
    </Grid>
  );
}
