import React from 'react'
import {
  GridListTileBar,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'white',
  }
}))

export function TitleItem({ tile, galleryId }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  return <>
    <img
      src={`https://api.lj-ottnang.at/media/gallery/${galleryId}/file/${tile.name}`}
      alt={tile.name}
    />
    <GridListTileBar
      title={tile.name}
      titlePosition="top"
      actionIcon={
        <IconButton className={classes.icon} onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
      }
    />
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu} disabled>
        <ListItemIcon>
          <DeleteForeverIcon />
        </ListItemIcon>
        <ListItemText>
          Löschen
        </ListItemText>
      </MenuItem>
    </Menu>
  </>
}