import React, { useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuItem,
} from "@material-ui/core";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import EventSeatOutlinedIcon from "@material-ui/icons/EventSeatOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PermMediaOutlinedIcon from "@material-ui/icons/PermMediaOutlined";

function Navigation({ value, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const views = [
      "#events",
      "#counter",
      "#media",
      "#vorstand",
      "#history",
      "#settings",
    ];
    const idx = views.indexOf(document.location.hash);
    if (idx >= 0) onChange(idx);
  }, [onChange]);

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const menuClick = (event, newValue) => {
    switch (newValue) {
      case "boardmembers":
        onChange(3);
        break;

      case "history":
        onChange(4);
        break;

      case "appSettings":
        onChange(5);
        break;

      default:
        console.error(`key ${newValue} isnt recognized`);
        break;
    }
    handleClose(event);
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          if (newValue === 3) {
            setAnchorEl(event.currentTarget);
          } else {
            onChange(newValue);
          }
        }}
      >
        <BottomNavigationAction label="Events" icon={<EventOutlinedIcon />} />
        <BottomNavigationAction
          label="Teilnehmer"
          icon={<EventSeatOutlinedIcon />}
        />
        <BottomNavigationAction
          label="Medien"
          icon={<PermMediaOutlinedIcon />}
        />
        <BottomNavigationAction
          label="Einstellungen"
          icon={<SettingsOutlinedIcon />}
        />
      </BottomNavigation>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => menuClick(e, "boardmembers")}>
          Vorstand
        </MenuItem>
        <MenuItem disabled onClick={(e) => menuClick(e, "history")}>
          Geschichte
        </MenuItem>
        <MenuItem onClick={(e) => menuClick(e, "appSettings")}>
          Sonstiges
        </MenuItem>
      </Menu>
    </>
  );
}

export { Navigation };
