import React, { useState, useMemo } from "react";
import { Typography, Container, Paper } from "@material-ui/core";
import { useCallback } from "react";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import { MediaListItem } from "./MediaListItem";
import { MediaGalleryDialog } from "./MediaGalleryDialog";
import { useData } from "../logic/backendFetch";
import { deleteData, updateOrCreateData } from "../logic/api";
import { DataLayout } from "../components/DataLayout";

export function MediaView() {
  const { data, loading, error, reload } = useData("media/gallery");
  const [selectedGallery, setSelectedGallery] = useState(null);

  const [
    isGalleryCreationDialogOpen,
    setIsGalleryCreationDialogOpen,
  ] = useState(false);

  const years = useMemo(() => {
    if (data)
      return Array.from(new Set(data.map((gal) => gal.year))).sort(
        (a, b) => b - a
      );
    return [];
  }, [data]);

  const createOrUpdateGallery = useCallback(
    (e, gallery) => {
      if (gallery) {
        updateOrCreateData("media/gallery", gallery)
          .then(() => {
            reload();
            setIsGalleryCreationDialogOpen(false);
          })
          .catch(() => alert("Fehler!"));
      } else {
        setIsGalleryCreationDialogOpen(false);
      }
    },
    [reload]
  );

  const deleteGallery = useCallback(
    (id) => {
      deleteData("media/gallery", id)
        .then(reload)
        .catch((error) => alert("Fehler!"));
    },
    [reload]
  );

  return (
    <DataLayout
      loading={loading}
      error={error}
      fab={{
        onClick: () => {
          setSelectedGallery((x) => {});
          setIsGalleryCreationDialogOpen((x) => true);
        },
        title: "Gallerie erstellen",
        icon: AddPhotoAlternateOutlinedIcon,
      }}
    >
      <Container>
        {years &&
          years.map((year) => {
            return (
              <div key={year}>
                <br />
                <Typography color="primary" variant="subtitle1">
                  {year}
                </Typography>
                <Paper square>
                  {data
                    .filter((g) => g.year === year)
                    .map((gal) => (
                      <MediaListItem
                        gallery={gal}
                        key={gal.id}
                        onDelete={deleteGallery}
                        onChange={createOrUpdateGallery}
                        reload={reload}
                      />
                    ))}
                </Paper>
              </div>
            );
          })}
      </Container>
      <MediaGalleryDialog
        open={isGalleryCreationDialogOpen}
        onClose={createOrUpdateGallery}
        gallery={selectedGallery}
      />
    </DataLayout>
  );
}
