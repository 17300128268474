import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

export function Loading() {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ height: "100%", width: "100%" }}
    >
      <CircularProgress />
    </Grid>
  );
}
