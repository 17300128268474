import axios from "axios";
import { API_BASE } from "../config";

const TOKEN = "YXBpOjdSYnRwRUQzS3M0OTJ4aU4=";

async function deleteData(route, id) {
  return await axios.delete(`${API_BASE}/${route}/${id}`, {
    headers: {
      Authorization: `Basic ${TOKEN}`,
    },
  });
}

async function createData(route, body) {
  return await axios.post(`${API_BASE}/${route}`, body, {
    headers: {
      Authorization: `Basic ${TOKEN}`,
    },
  });
}

async function updateData(route, body) {
  return await axios.put(`${API_BASE}/${route}`, body, {
    headers: {
      Authorization: `Basic ${TOKEN}`,
    },
  });
}

async function updateOrCreateData(route, body) {
  if (body.id !== undefined && body.id !== null)
    return await updateData(route, body);
  else return await createData(route, body);
}

async function upload(route, fieldName, file) {
  const formData = new FormData();
  formData.append(fieldName, file);
  return await axios.post(`${API_BASE}/${route}`, formData, {
    headers: {
      Authorization: `Basic ${TOKEN}`,
      "Content-Type": "multipart/form-data",
    },
  });
}

export { deleteData, updateData, createData, updateOrCreateData, upload };
