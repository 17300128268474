import React from "react";
import clsx from "clsx";
import { Fab, Grow, makeStyles } from "@material-ui/core";
import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";

const useStyles = makeStyles((theme) => ({
  rootFab: {
    position: "relative",
    height: "100%",
  },
  container: {
    overflowY: "scroll",
    height: "100%",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  }
}));

export function createIcon(WrappedComponent, className) {
  return class FabIcon extends React.Component {
    render() {
      return <WrappedComponent />;
    }
  }
}

export function DataLayout({ loading, error, children, fab }) {
  const classes = useStyles();

  if (error) return <ErrorMessage />;
  else if (loading) return <Loading />;
  else {
    if (!fab) return <>{children}</>;
    else {
      return (
        <div className={classes.rootFab}>
          <div className={classes.container}>{children}</div>
          {fab && (
            <Grow in>
              <Fab
                color="primary"
                className={classes.fab}
                onClick={fab.onClick}
                variant={fab.title ? "extended" : "round"}
              >
                <fab.icon className={clsx({
                  [classes.extendedIcon]: fab.title
                })} />
                {fab.title}
              </Fab>
            </Grow>
          )}
        </div>
      );
    }
  }
}
