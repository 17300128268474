import React, { useState, useCallback } from "react";
import {
  ListItemText,
  IconButton,
  ListItemAvatar,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PhotoAlbumOutlinedIcon from "@material-ui/icons/Image";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { MediaGalleryDialog } from "./MediaGalleryDialog";
import { API_BASE } from "../config";
import { ImageUpload } from "../components/ImageUpload";
import { upload } from "../logic/api";
import { GalleryMedia } from "./GalleryMedia";
import { Tooltip } from "@material-ui/core";

export function MediaListItem({ gallery, onChange, onDelete, reload }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isThumbnailUploadOpen, setIsThumbnailUploadOpen] = useState(false);

  const handleSave = useCallback(
    (files) => {
      setIsThumbnailUploadOpen(false);
      upload(`media/gallery/thumbnail/${gallery.id}`, "thumbnail", files[0])
        .then(reload)
        .catch(() => {
          alert("Fehler beim hochladen!");
        });
    },
    [gallery.id, reload]
  );

  const deleteAction = useCallback(() => {
    if (onDelete) onDelete(gallery.id);
  }, [gallery, onDelete]);

  const editAction = useCallback(
    (event, gal) => {
      if (onChange) onChange(event, gal);
      setIsOpen(false);
    },
    [onChange]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary>
        {gallery.thumbnail && (
          <ListItemAvatar>
            <Avatar
              style={{ height: "32px", width: "32px" }}
              src={`${API_BASE}/media/gallery/thumbnail/${gallery.id}`}
              variant="rounded"
            />
          </ListItemAvatar>
        )}
        <ListItemText primary={gallery.name} />
      </AccordionSummary>
      <AccordionDetails>
        <GalleryMedia id={gallery.id} />
      </AccordionDetails>
      <AccordionActions>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setIsThumbnailUploadOpen(true)}
          color={!gallery.thumbnail ? "secondary" : "default"}
          startIcon={<PhotoAlbumOutlinedIcon />}
        >
          Thumbnail{matches && " hochladen"}
        </Button>
        <Tooltip title="Gallerie löschen">
          <IconButton onClick={deleteAction}>
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Gallerie Beschreibung ändern">
          <IconButton onClick={() => setIsOpen(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </AccordionActions>
      <MediaGalleryDialog
        open={isOpen}
        onClose={editAction}
        gallery={gallery}
      />
      <ImageUpload
        dialogTitle={`Thumbnail von ${gallery.name} aktualisieren`}
        open={isThumbnailUploadOpen}
        onSave={handleSave}
        onClose={() => setIsThumbnailUploadOpen(false)}
      />
    </Accordion>
  );
}
