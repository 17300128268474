import React, { useCallback, useState } from "react";
import {
  Container,
  GridList,
  GridListTile,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import { useSnackbar } from "notistack";
import { DataLayout } from "../components/DataLayout";
import { ImageUpload } from "../components/ImageUpload";
import { TitleItem } from "./TitleItem";
import { useData } from "../logic/backendFetch";
import { upload } from "../logic/api";

const useStyles = makeStyles(() => ({
  gridList: {
    maxHeight: '50vh'
  },
  container: {
    overflow: 'hidden'
  }
}))

export function GalleryMedia({ id }) {
  const { data, loading, error, reload } = useData(`media/gallery/${id}/files`);
  const [uploadDialog, setUploadDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const handleUpload = useCallback(
    (files) => {
      Promise.all(
        files.map((file) => upload(`media/gallery/${id}/file`, "file", file))
      )
        .catch((e) => {
          enqueueSnackbar(`Fehler beim hochladen! (${e})`, {
            variant: "error",
          });
        })
        .finally(() => {
          reload();
          setUploadDialog(false);
        });
    },
    [enqueueSnackbar, id, reload]
  );

  return (
    <div style={{ minHeight: "10vh", width: "100%", position: "relative" }}>
      <DataLayout
        loading={loading}
        error={error}
        fab={{
          onClick: () => setUploadDialog(true),
          icon: CloudUploadRoundedIcon,
        }}
      >
        <Container className={classes.container}>
          {data && data.length > 0 && (
            <GridList cellHeight={240} cols={isMobile ? 3 : 1} className={classes.gridList}>
              {data.map((tile) => (
                <GridListTile key={tile.id} cols={tile.cols || 1}>
                  <TitleItem tile={tile} galleryId={id} />
                </GridListTile>
              ))}
            </GridList>
          )}
          {(!data || data.length === 0) && (
            <Typography color="primary">Keine Medien gefunden</Typography>
          )}
        </Container>
      </DataLayout>
      <ImageUpload
        dialogTitle={`Medin hochladen (max 30 auf einmal)`}
        open={uploadDialog}
        onSave={handleUpload}
        onClose={() => setUploadDialog(false)}
        filesLimit={30}
      />
    </div>
  );
}
