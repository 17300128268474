import React, { useCallback, useState } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import { formatPhone, generateAvatarLink } from "../logic";
import { useData } from "../logic/backendFetch";
import { DataLayout } from "../components/DataLayout";
import { MemberDialog } from "./MemberDialog";
import { updateOrCreateData, upload } from "../logic/api";
import { Loading } from "../components/Loading";
import { ImageUpload } from "../components/ImageUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: "16vw",
    margin: theme.spacing(2),
    display: "inline-block",
  },
  media: {
    height: 400,
  },
}));

function BoardMemberCard({ id, name, role, phone, onSelect }) {
  const classes = useStyles();
  const [uploadDialogState, setUploadDialogState] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleSave = useCallback(
    (files) => {
      setUploadDialogState(false);
      setUploading(true);
      upload(`boardmember/avatar/${id}`, "avatar", files[0])
        .then(() => {
          setUploading(false);
        })
        .catch(() => {
          alert("Fehler beim hochladen!");
          setUploading(false);
        });
    },
    [id]
  );

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea
          disabled={uploading}
          onClick={() => setUploadDialogState(true)}
        >
          {uploading && <Loading />}
          <CardMedia
            className={classes.media}
            image={!uploading && generateAvatarLink(id)}
            title={`${name} Profilbild`}
          />
        </CardActionArea>
        <CardContent>
          <Typography variant="subtitle1" component="h2" color="primary">
            {role.titel}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            {name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            align="left"
          >
            Telefon: {(phone && formatPhone(phone)) || "NEIN"}
          </Typography>
        </CardContent>
        <CardActions disabled={uploading}>
          <Button size="small" color="primary" onClick={onSelect}>
            Bearbeiten
          </Button>
          <Button size="small">Löschen</Button>
        </CardActions>
      </Card>
      <ImageUpload
        dialogTitle={`Profilbild von ${name} aktualisieren`}
        open={uploadDialogState}
        onSave={handleSave}
        onClose={() => setUploadDialogState(false)}
      />
    </>
  );
}

function BoardMembers() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const { data, loading, error, reload } = useData("boardmember");

  const onSave = useCallback(
    (member) => {
      const { role, ...rest } = member;
      updateOrCreateData("boardmember", rest)
        .then(reload)
        .catch(() => alert("Fehler!"));
    },
    [reload]
  );

  const onNewMember = useCallback(() => {
    setSelectedMember({ name: "", phone: null, roleId: null });
    setDialogOpen(true);
  }, []);

  return (
    <DataLayout
      loading={loading}
      error={error}
      fab={{ onClick: onNewMember, icon: PersonAddOutlinedIcon, title: "Vorstandsmitglied hinzufügen" }}
    >
      <div>
        {data &&
          data.map((member) => (
            <BoardMemberCard
              key={member.name}
              {...member}
              onSelect={() => {
                setSelectedMember(member);
                setDialogOpen(true);
              }}
            />
          ))}
        {(!data || data.length === 0) && (
          <Typography>Keine Vorstandsmitglieder geladen</Typography>
        )}
      </div>
      <MemberDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        member={selectedMember}
        onSave={onSave}
      />
    </DataLayout>
  );
}

export { BoardMembers };
