import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DataLayout } from "../components/DataLayout";
import { useData } from "../logic/backendFetch";

/**
 * Create or Update Boardmember
 */
function MemberDialog({ open, member, onClose, onSave }) {
  const [localMember, setLocalMember] = useState(null);
  const { data, loading, error } = useData("boardmemberrole");

  useEffect(() => {
    setLocalMember(member);
  }, [member]);

  const handleChange = (event) => {
    setLocalMember({
      ...localMember,
      roleId: event.target.value,
    });
  };

  const handleNameChange = (event) => {
    setLocalMember({
      ...localMember,
      name: event.target.value,
    });
  };

  const handlePhoneChange = (event) => {
    let next = event.target.value.replace(" ", "");
    if (next === "") next = null;
    setLocalMember({
      ...localMember,
      phone: next,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Vorstandsmitglied</DialogTitle>
      <DialogContent>
        <DataLayout loading={loading} error={error}>
          {localMember && (
            <>
              <TextField
                label="Name"
                value={localMember.name}
                variant="outlined"
                onChange={handleNameChange}
                fullWidth
              />
              <br />
              <br />
              <TextField
                label="Telefonnummer"
                value={localMember.phone}
                variant="outlined"
                onChange={handlePhoneChange}
                error={
                  localMember.phone && !/\+[0-9]{7,}$/.test(localMember.phone)
                }
                helperText="Internationales Format benötigt: +43..."
                fullWidth
              />
              <br />
              <br />
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="role-select-label">Rolle</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  label="Rolle"
                  value={localMember.roleId || localMember.role?.id}
                  onChange={handleChange}
                >
                  {data
                    .sort((a, b) => a.rank - b.rank)
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.titel}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          )}
        </DataLayout>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Schließen</Button>
        {localMember && (
          <Button
            variant="outlined"
            onClick={() => {
              onSave(localMember);
              onClose();
            }}
            color="primary"
            disabled={
              !(
                (localMember.roleId || localMember.role?.id) &&
                localMember.name.length > 3
              )
            }
          >
            Speichern
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export { MemberDialog };
