import React, { useCallback, useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  TextField,
  DialogContent,
  Grid,
} from "@material-ui/core";

export function MediaGalleryDialog({ open, onClose, gallery = {} }) {
  const [year, setYear] = useState(gallery?.year || new Date().getFullYear());
  const [name, setName] = useState(gallery?.name);

  const onSave = useCallback(
    (e) => {
      onClose(e, { ...gallery, name, year });
    },
    [gallery, name, onClose, year]
  );

  const valid = useMemo(() => {
    return name && name.length > 3 && year && Number.parseInt(year, 10) > 2000;
  }, [name, year]);

  if (!gallery) return <></>;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Gallerie {(gallery.id && "ändern") || "erstellen"}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              type="year"
              variant="filled"
              label="Jahr"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="filled"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button color="primary" onClick={onSave} disabled={!valid}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
